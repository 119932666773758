import React from 'react';
import { Link } from 'gatsby';

import ContentNarrow from '@components/ContentNarrow';
import Layout from '@components/Layout';

import { Container } from '@elements';

const Page404 = () => (
  <>
    <Layout>
      <Container>
        <ContentNarrow className="pb-8">
          <h1 className="mb-0">404 Error: Page not found!</h1>
          <Link to="/" alt="got to homepage">
            Go to Homepage
          </Link>
        </ContentNarrow>
      </Container>
    </Layout>
  </>
);

export default Page404;
